import React from 'react'
//import { Link } from 'gatsby'
import { Helmet } from "react-helmet"
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'
import { AnchorLink } from "gatsby-plugin-anchor-links";

const Landing = (props) => (
    <Layout>
        <Helmet>
            <title>About Block & Tackle</title>
            <meta name="description" content="Our people focused approach" />
        </Helmet>

        <BannerLanding />

        <div id="main">
        <section id="two" className="alt">
            <div className="inner">
                <header className="major">
                    <h2>Finding the right people</h2>
                </header>
                <p>Every business manual since the beginning of time will tell you hiring is the most important part of growing your business. For something we all know is so crucial, recruitment gets a bad wrap.</p>
                <p>We’ve been on both sides of the pitch: internal agency hiring managers, and external recruitment partners, and for us, it’s about intimately understanding the type of project we’re hiring into.</p>
                <p>We treat the people and the process with the respect they deserve and make it as enjoyable as possible for everyone (even if a candidate isn’t right for the role). </p>
                <ul className="actions">
                    <li><AnchorLink to="/about/#contact-2" className="button alt-dark next">Let's talk</AnchorLink></li>
                </ul>
            </div>
        </section>
        </div>
        <section id="two">
            <div className="inner">
                <header className="major">
                    <h2>Investing in people</h2>
                </header>
                <p>What makes great teams fall apart? A lack of learning and development, wellbeing support, breakdown of trust and respect, or just a fizzling out of the initial excitement?</p>
                <p>Wherever you are in your journey, we work closely with your teams to define tailored solutions to bring them back together for their best work.</p>
                <p>Our learning management system is custom built for your organisation, designed to fit the needs of your people and guiding your business to success.</p>
                <ul className="actions">
                    <li><AnchorLink to="/about/#contact-2" className="button alt-dark next">Contact us</AnchorLink></li>
                </ul>
            </div>
        </section>

    </Layout>
)

export default Landing