import React from 'react'

const BannerLanding = (props) => (
    <section id="banner" className="style2 landing">
        <div className="inner">
            <div className="content">
                <h1>We are people focused agency experts: mentors, coaches, consultants, and legal professionals. We know when people come together, celebrate our differences, and care for each other, amazing things are possible.</h1>
            </div>
            <div>
                <p>We work with you to deliver:</p>
            </div>
            <div>
                <ul>
                    <li>Happy, united and collaborative teams</li>
                    <li>Individuals feeling trusted and respected</li>
                    <li>Projects that exceed expectations</li>
                    <li>Issues dealt with and properly resolved</li>
                </ul>
            </div>
            <div className="tb">
                <h2>We believe our mission is achievable when teams focus on two things:</h2>
                <br></br>
            </div>
        </div>
    </section>
)

export default BannerLanding
